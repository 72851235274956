import ObjectStorage from "@/assets/lib/utility/ObjectStorage";
import {
  ChatStatus,
  CustomButtonCreate,
  CustomButtonEvent,
  CustomMsg,
  CustomMsgUi,
  EventType,
  MsgSenderType
} from "@/assets/lib/type/Types";
import ChatUtility from "@/assets/lib/util/ChatUtility";
import NumberStorage from "@/assets/lib/utility/NumberStorage";
import {Faq} from "@/assets/lib/type/ApiResponseTypes";

export default class ChatModel {
  msgList: CustomMsg[]
  faqList: Faq[] = []
  visitDate: number;
  status: ChatStatus
  private msgListObj: ObjectStorage<CustomMsg[]> = new ObjectStorage<CustomMsg[]>('chat-msg-list-' + ChatUtility.createKey(), [])
  private visitObj: NumberStorage = new NumberStorage('chat-msg-visit', 0)

  constructor() {
    this.msgList = this.msgListObj.value
    this.visitDate = this.visitObj.value
    this.status = ChatStatus.beFore
  }

  get getMsgList() {
    return this.msgList;
  }

  get faqs() {
    return this.faqList
  }

  unReadMsgList() {
    return this.msgList.filter(m => !m.isRead);
  }

  setVisit() {
    this.visitObj.value = new Date().getTime()
  }

  isFirstVisitToday() {
    const now = new Date().getTime();
    return !!(now - (this.visitDate + (24 * 60 * 60 * 1000)));
  }

  addMsg(msg: CustomMsg) {
    this.msgList.splice(this.msgList.length, 0, msg);
    if (msg.sender === MsgSenderType.bot) {
      const customEvent: CustomButtonEvent = {
        eventType: EventType.Common,
        value: {
          contentTitle: '',
          contentKey: '',
          data: msg.text
        }
      }
      window.parent.postMessage(customEvent, '*')
    }
    this.save();
  }

  defaultMsgTemplate(isRead = false): CustomMsg {
    return {
      uuid: ChatUtility.createKey(),
      type: '',
      ui: CustomMsgUi.Text,
      createdAt: new Date().getTime(),
      sender: MsgSenderType.bot,
      text: '',
      isRead: isRead
    }
  }

  customButtonCreater(): CustomButtonCreate {
    return {
      text: '',
      code: {
        eventType: EventType.Common,
        value: {
          contentKey: '',
          contentTitle: '',
          data: ''
        }
      }
    }
  }

  save() {
    this.msgListObj.save()
  }

  clear() {
    this.msgListObj.value = [];
    this.msgList = [];
    this.msgListObj.save()
  }

}
