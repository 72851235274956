
import {Component, Emit, Vue} from "vue-property-decorator";

@Component({name: "MicButton", components: {}})
export default class MicButton extends Vue {

  @Emit('micButtonClick')
  micButtonClick() {
    //
  }
}
