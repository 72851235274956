import ChatModel from "@/assets/lib/model/ChatModel";
import {ChatStatus, CustomButtonEvent, CustomMsg, CustomMsgUi, EventType, MsgSenderType} from "@/assets/lib/type/Types";
import RasaApi from "@/assets/lib/api/RasaApi";
import GenieApi from "@/assets/lib/api/GenieApi";
import krStDictApi from "@/assets/lib/api/KrStandardDictApi";
import FaqApi from "@/assets/lib/api/FaqApi";
import {Faq} from "@/assets/lib/type/ApiResponseTypes";
import ChatRoom from "@/views/ChatRoom/ChatRoom.vue";


export default class ChatController {
  model: ChatModel;
  chatRoom?: ChatRoom

  constructor(model: ChatModel) {
    this.model = model
  }

  setVueComponent(chatRoom: ChatRoom) {
    this.chatRoom = chatRoom;
  }

  goDownScroll() {
    requestAnimationFrame(() => {
      this.chatRoom?.goDownScroll();
    })
  }


  setStatus(status: ChatStatus) {
    if (this.model.status === ChatStatus.beFore && status === ChatStatus.common) {
      const msg = this.msgTemplate(true);
      msg.ui = CustomMsgUi.Time;
      msg.sender = MsgSenderType.notice;
      this.appendMsg(msg);
    }
    this.model.status = status;
  }

  msgTemplate(isRead = false) {
    return this.model.defaultMsgTemplate(isRead);
  }

  appendTime() {
    const msg = this.msgTemplate(true);
    const time = new Date();
    const hour = time.getHours() >= 12 ? `PM ${time.getHours() - 12}` : `AM ${time.getHours()}`
    msg.ui = CustomMsgUi.Time
    msg.sender = MsgSenderType.notice
    msg.text = `${time.toLocaleDateString()} ${hour}:${time.getMinutes()}`
    this.model.addMsg(msg)
  }

  appendMyMsg(msgText: string, isAnswer = true) {
    const msg = this.msgTemplate(true);
    msg.sender = MsgSenderType.user;
    msg.text = msgText;
    msg.ui = CustomMsgUi.Text;
    this.model.addMsg(msg);

    if (isAnswer) {

      const answerMsg = this.msgTemplate(true);

      switch (this.model.status) {
        case ChatStatus.common:
          this.getAnswer(msgText);
          break;
        case ChatStatus.giene:
          this.getAnswerGiene(msgText).then((res) => {
            if (res) {
              answerMsg.text = res
              this.model.addMsg(answerMsg)
            } else {
              answerMsg.text = '죄송해요. \n (AI슈타인)'
              this.model.addMsg(answerMsg)
            }

            this.setStatus(ChatStatus.common)
          });
          break;
        case ChatStatus.dict:
          this.getAnswerKoreanStandardDict(msgText).then(res => {
            if (res) {
              answerMsg.text = res
              this.model.addMsg(answerMsg)
            } else {
              answerMsg.text = '표준 국어 대사전에 없는 단어 입니다. \n (AI슈타인)'
              this.model.addMsg(answerMsg)
            }
            this.setStatus(ChatStatus.common)
          });
          break;
      }
    }

  }


  async getAnswer(msgText: string, isVoiceAnswer = false) {
    const rasaAnswer = await this.getAnswerRasa(msgText) as string;
    const msg = this.model.defaultMsgTemplate()

    if (rasaAnswer) {
      if (rasaAnswer === 'FAQ') {
        await this.openFaq();
        return;
      }
      msg.text = rasaAnswer;
      this.model.addMsg(msg);
      if (isVoiceAnswer) this.voiceAnswer(msg.text)
      return;
    }

    const dictAnswer = await this.getAnswerKoreanStandardDict(msgText);
    if (dictAnswer) {
      msg.text = dictAnswer
      this.model.addMsg(msg);
      if (isVoiceAnswer) this.voiceAnswer(msg.text)
      return;
    }

    const genieAnswer = await this.getAnswerGiene(msgText);
    if (genieAnswer) {
      msg.text = genieAnswer
      this.model.addMsg(msg);
      if (isVoiceAnswer) this.voiceAnswer(msg.text)
      return;
    }

    msg.text = '도움을 드리지못해 죄송합니다. \n (AI슈타인)';
    this.model.addMsg(msg);
    if (isVoiceAnswer) this.voiceAnswer('도움을 드리지못해 죄송합니다.')
    return;

  }


  async getAnswerRasa(msgText: string): Promise<string | undefined> {
    return RasaApi.commonAnswer({message: msgText}).then((res) => {
      try {
        //@ts-ignore
        if (res[0].text.length > 0) {
          let text = ''
          //@ts-ignore
          res.forEach((item) => text += item.text)
          //@ts-ignore
          return text
        }
      } catch (e) {
        return undefined;
      }
    })
  }


  async getAnswerGieneKeyword(msgText: string) {
    const msg = this.model.defaultMsgTemplate()
    const getAnswerGieneChatBot = await GenieApi.getAnswerGieneChatBot({text: msgText})
    if (getAnswerGieneChatBot?.code === 200 && getAnswerGieneChatBot.data.answer.message !== '') {
      const text = getAnswerGieneChatBot.data.answer.message
      msg.text = text + '\n(Genie)가 알려줬어요.';
      this.model.addMsg(msg);
      this.voiceAnswer(text)
    }
  }

  async getAnswerGiene(msgText: string): Promise<string | undefined> {
    const getAnswerGieneChatBot = await GenieApi.getAnswerGieneChatBot({text: msgText})
    if (getAnswerGieneChatBot?.code === 200 && getAnswerGieneChatBot.data.answer.message !== '') return getAnswerGieneChatBot.data.answer.message + '\n(Genie)가 알려줬어요';
    return undefined
  }

  async getAnswerKoreanStandardDictKeyword(msgText: string) {
    const msg = this.model.defaultMsgTemplate()
    const krStDictSearch = await krStDictApi.krStDictSearch({word: msgText})
    if (krStDictSearch) {
      const text = krStDictSearch.data.word
      msg.text = text + '\n(표준 국어 대사전) 에서 찾아봤어요.';
      this.model.addMsg(msg);
      this.voiceAnswer(text)
    }
  }

  async getAnswerKoreanStandardDict(msgText: string): Promise<string | undefined> {
    const krStDictSearch = await krStDictApi.krStDictSearch({word: msgText})
    if (krStDictSearch?.code === 200) {
      return krStDictSearch.data.word + '\n(표준 국어 대사전) 에서 찾아봤어요.';
    }
    return undefined
  }

  voiceAnswer(text: string) {
    const customEvent: CustomButtonEvent = {
      eventType: EventType.Voice,
      value: {
        contentTitle: '',
        contentKey: '',
        data: text
      }
    }
    window.parent.postMessage(customEvent, '*')
  }


  appendMsg(msg: CustomMsg) {
    this.model.addMsg(msg);
  }

  sendMsgToParent(data: any) {
    window.parent.postMessage(data, '*')
  }

  sendCustomButtonEventToParent(btnEvent: CustomButtonEvent) {
    window.parent.postMessage(btnEvent, '*')
  }

  async openFaq() {
    const firstFaqs = await FaqApi.firstFaq({})
    if (!firstFaqs) return;
    this.model.faqList = firstFaqs?.data.faqs
    this.setStatus(ChatStatus.shortCut)
    this.goDownScroll()
    return true;
  }

  async clickFaq(faq: Faq) {
    if (faq.action) this.sendAnswer(faq.action)
    else {
      const subFaq = await FaqApi.subFaq({category: faq.category})
      if (!subFaq) return
      this.model.faqList = JSON.parse(JSON.stringify(subFaq.data.faqs))
    }
  }


  sendAnswer(intent: string) {
    this.setStatus(ChatStatus.common)
    FaqApi.answerFaq({intent: intent}, (res) => {
      const intent = res.data.intent
      const msg = this.msgTemplate(true)
      if (intent.event_data) {
        const button = this.model.customButtonCreater()
        button.code.eventType = intent.event_type as EventType
        button.code.value.data = intent.event_data
        button.text = intent.response
        msg.ui = CustomMsgUi.Card
        msg.buttons = [button]
      } else {
        msg.text = intent.response
      }
      this.appendMsg(msg)
    })
    //
  }


}
