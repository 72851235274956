
import {Component, Vue} from "vue-property-decorator";
import CommonTextBox from "@/views/ChatRoom/component/CommonTextBox.vue"
import AiSteinAvatar from "@/views/ChatRoom/component/AiSteinAvatar.vue";
import CommonChatButton from "@/views/ChatRoom/component/CommonChatButton.vue";

@Component({name: "GoToSpeakingPractice", components: {AiSteinAvatar, CommonTextBox, CommonChatButton}})
export default class GoToSpeakingPractice extends Vue {

}
