
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {CustomButtonCreate} from "@/assets/lib/type/Types";

@Component({name: "CommonChatButton", components: {}})
export default class CommonChatButton extends Vue {
  @Prop() button !: CustomButtonCreate

  @Emit('clickCustomButton')
  buttonClick() {
    return this.button.code
  }

}
