import AuthController from "@/assets/lib/controller/AuthController";

class ChatManager {
  auth = new AuthController()

  constructor() {
    if ((window.location.href.indexOf('192.168') >= 0)) {
      // @ts-ignore
      window.ChatManager = this
    }
  }
}

export default new ChatManager()
