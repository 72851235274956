
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import ChatModal from '@/views/ChatRoom/ChatModal.vue'
import Floating from '@/components/Floating.vue'
import ChatModel from "@/assets/lib/model/ChatModel";
import {FloatingStatus} from "@/assets/lib/type/Types";

@Component({
  name: 'FloatingWrapper',
  components: {Floating, ChatModal}
})
export default class FloatingWrapper extends Vue {

  @Prop() chatModel!: ChatModel;
  @Prop() floatingStatus!: FloatingStatus;
  FloatingStatus = FloatingStatus;

  defaultChat = true
  defaultBtn = false
  defaultFloat = true

  showModal() {
    this.defaultChat = true;
    this.defaultBtn = true;
    this.defaultFloat = false;
  }

  @Emit('showModal')
  sendShowModal() {
    //
  }

  @Emit('closeModal')
  sendCloseModal() {
    //
  }

  closeModal() {
    this.defaultChat = false
    this.defaultBtn = false
    this.defaultFloat = true
  }


}
