type EnvKind = 'local' | 'stg' | 'prod';

export default class Config {
  private static config = {
    IS_LOCAL: window.origin.indexOf('localhost') > -1,
    API_HOST: "https://aitutor.sketchlab.co.kr",
    RASA_HOST: "https://aitutor-rasa.sketchlab.co.kr",
    RASA_WHITE_LIST: ["http:localhost:8080", "https://aitutor.sketchlab.co.kr", "http://aitutor.sketchlab.co.kr"],
    ST_DICT_HOST: "https://stdict.korean.go.kr",
    ST_DICT_KEY: "993D9407637546829CFF0CD67DB0D66B",
    GENIE_HOST: "https://openapi-de.gigagenie.ai:9080",
    CLIENT_ID: "STgwMDI3NDg6MTY2NzM0OTIxNTYwOA==",
    CLIENT_KEY: "Y2xpZW50X2tleToxNjY3MzQ5MjE1NjA4",
    CLIENT_SECRET: "Y2xpZW50X3NlY3JldDoxNjY3MzQ5MjE1NjA4",
    OAuthClientId: "e8500c4bf38b876fd38a0da1eb9ad4e4393fb7904cd3a3b6338c95ffa9b94c47",
    OAuthClientSecret: "1b6c9121ee173be1d8da0477bd202f3c95be5c2c4df87e4159a881ace6fe4104",
    CERT_URL: "https://api.gigagenie.ai/api/v1/aiportal/cert"
  };

  static get CONFIG() {
    this.config.IS_LOCAL ? this.config.API_HOST = 'http://192.168.0.13:8083' : ''
    //this.config.IS_LOCAL ? this.config.API_HOST = 'https://aitutor.sketchlab.co.kr' : ''
    return this.config;
  }
}
