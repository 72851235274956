
import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import ChatModel from "@/assets/lib/model/ChatModel";
import {ChatStatus, CustomButtonEvent, CustomMsgUi, EventType, MsgSenderType} from "@/assets/lib/type/Types"
import AiSteinIntroduce from "@/views/ChatRoom/component/AiSteinIntroduce.vue";
import SolveQuiz from "@/views/ChatRoom/component/SolveQuiz.vue";
import MyConversation from "@/views/ChatRoom/component/MyConversation.vue";
import AiSteinConversation from "@/views/ChatRoom/component/AiSteinConversation.vue";
import GoToQuiz from "@/views/ChatRoom/component/GoToQuiz.vue";
import TextBook from "@/views/ChatRoom/component/TextBook.vue";
import ShortcutButton from "@/views/ChatRoom/component/ShortcutButton.vue";
import ShortcutQuestion from "@/views/ChatRoom/component/ShortcutQuestion.vue";
import GoToSpeakingPractice from "@/views/ChatRoom/component/GoToSpeakingPractice.vue";
import MicButton from "@/views/ChatRoom/component/button/MicButton.vue";
import ChatController from "@/assets/lib/controller/ChatController";
import ChatUtility from "@/assets/lib/util/ChatUtility";
import AiSteinAvatar from "@/views/ChatRoom/component/AiSteinAvatar.vue";
import AiConversationCard from "@/views/ChatRoom/component/AiConversationCard.vue";
import Floating from '@/components/Floating.vue'
import '@/assets/style/font.css'
import {Faq} from "@/assets/lib/type/ApiResponseTypes";
import CommonChatButton from './component/CommonChatButton.vue';

@Component({
  components: {
    Floating,
    AiSteinAvatar,
    AiSteinIntroduce, ShortcutButton, AiSteinConversation, TextBook, GoToQuiz, SolveQuiz,
    GoToSpeakingPractice, MyConversation, ShortcutQuestion, MicButton, AiConversationCard, CommonChatButton
  }
})
export default class ChatRoom extends Vue {

  @Ref() refMsgText!: HTMLTextAreaElement;
  @Ref() refContainer!: HTMLElement;
  @Prop() chatModel!: ChatModel;
  @Prop() isFull!: boolean
  chatController: ChatController | null = null;
  MsgSenderType = MsgSenderType;
  CustomMsgUi = CustomMsgUi;
  ChatStatus = ChatStatus;
  msgText = '';
  isAvailableSend = this.msgText.length > 0;
  observer: MutationObserver | null = null;
  isFaqClicked = false

  get isCommonStatus() {
    return this.chatModel.status === ChatStatus.common || this.chatModel.status === ChatStatus.dict || this.chatModel.status === ChatStatus.giene
  }

  get msgList() {
    return this.chatModel ? this.chatModel.getMsgList : [];
  }

  get faqs() {
    return this.chatModel.faqs
  }

  onInputText(e: Event) {
    this.msgText = ChatUtility.changeSafeText((e.target as HTMLInputElement).value)
    this.isAvailableSend = this.msgText.length > 0;
  }

  created() {
    window.addEventListener('keydown', this.enterKeyListener)

    const callbackMutation = (mutationList: MutationRecord[], _observer: MutationObserver) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          console.log("자식 노드가 추가되거나 제거됐습니다.");
          requestAnimationFrame(() => {
            this.goDownScroll()
          })
        }
      }
    }

    this.observer = new MutationObserver(callbackMutation)
    requestAnimationFrame(() => {
      const test = document.querySelector('.msg-list-container') as HTMLElement;
      const config = {attributes: true, childList: true, subtree: true};
      if (test && this.observer) this.observer.observe(test, config);
      this.goDownScroll()
    })

  }

  mounted() {
    if (!this.chatController) this.chatController = new ChatController(this.chatModel);
    this.goDownScroll()
    if (this.chatModel.status === ChatStatus.beFore) {
      this.chatController.setStatus(ChatStatus.common);
    }
    this.chatController.setVueComponent(this)
    this.sendInitMsg();
  }

  goDownScroll() {
    this.refContainer.scrollTo({top: this.refContainer.scrollHeight, left: 0, behavior: 'auto'})
  }

  notGoDownScroll() {
    this.refContainer.scrollTo({top: 0, left: 0, behavior: 'auto'})
  }


  enterKeyListener(e: KeyboardEvent) {
    if (!this.refMsgText) return;
    //한글입력시 2번 이벤트 발생 방지
    if (e.isComposing || e.keyCode === 229) {
      return;
    }
    if (e.key === 'Enter' && !e.shiftKey) {
      this.sendMyMsg()
      return;
    }
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.enterKeyListener)
    if (this.observer) this.observer.disconnect()
  }

  sendMyMsg() {
    if (this.isAvailableSend) {
      this.chatController?.appendMyMsg(this.msgText);
      requestAnimationFrame(() => {
        this.msgText = ``;
        this.isAvailableSend = false;
      })
    }

  }

  sendInitMsg() {
    const customEvent: CustomButtonEvent = {
      eventType: EventType.Init,
      value: {
        contentTitle: '',
        contentKey: '',
        data: ''
      }
    }
    this.clickCustomButton(customEvent);
  }

  micButtonClick() {
    const customEvent: CustomButtonEvent = {
      eventType: EventType.Speech,
      value: {
        contentTitle: '',
        contentKey: '',
        data: ''
      }
    }
    this.clickCustomButton(customEvent);
  }

  clickCustomButton(customEvent: CustomButtonEvent) {
    this.chatController?.sendCustomButtonEventToParent(customEvent)
  }

  isViewAvatar(index: number) {
    if (index === this.msgList.length - 1) return true;
    if (this.msgList[index].sender != this.msgList[index + 1].sender) return true;
    return false;
  }

  openFaq() {
    this.isFaqClicked = false;
    this.chatController?.openFaq()
  }

  searchDict(dict: string) {
    if (!this.chatController) return;

    const msg = this.chatController.msgTemplate();

    if (dict === 'krDict') {
      this.chatController.setStatus(ChatStatus.dict)
      msg.text = '표준 국어 대사전에 검색하고 싶은 단어을 말해주세요.'
      this.chatController.appendMsg(msg)
    }
    if (dict === 'giene') {
      this.chatController.setStatus(ChatStatus.giene)
      msg.text = '저한테 물어보고 싶은 상식을 말해주세요.'
      this.chatController.appendMsg(msg)
    }
  }

  clickFaq(faq: Faq) {
    this.chatController?.clickFaq(faq)
    this.isFaqClicked = true;
  }


}

