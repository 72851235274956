import axios, {AxiosError} from "axios";
import {KrStDictSearchResponse, ResponseData} from "@/assets/lib/type/ApiResponseTypes";
import {KrStDictSearchRequest} from "@/assets/lib/type/ApiRequestTypes";
import Config from "@/config";
import Api from "@/assets/lib/api/Api";

export default class krStDictApi extends Api {
  private static krStDictAxios = axios.create({
    baseURL: Config.CONFIG.API_HOST
  })

  /**
   * 표준국어대사전 검색
   * @param data 검색할 단어는 인코딩필요
   * @param onSuc
   * @param onErr
   */
  static krStDictSearch<Q = KrStDictSearchRequest, R = ResponseData<KrStDictSearchResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.krStDictAxios, '/api/v1/dict', data, null, onSuc, onErr);
  }


}
