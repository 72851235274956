import {OAuthTokenResponse} from "@/assets/lib/type/ApiResponseTypes";

export interface UserSession {
  user_idx: number
  uid: string
  name: string
  token: null | OAuthTokenResponse
}


export interface ChatUser {
  type: 'STUDENT' | 'TEACHER'
  level: number
  name: string
  uid: string
  uuid: null | string
}

export interface UserUuid {
  uid: string
  uuid: null | string
}

export enum CustomMsgUi {
  Text = 'Text',
  Card = 'Card',
  Quiz = 'Quiz',
  Time = 'Time',
  SingleImg = 'SingleImg',
  SingleButton = 'SingleButton'
}

export enum MsgSenderType {
  bot = 'bot',
  notice = 'notice',
  user = 'user'
}

export interface ChatConfig {

}

export interface UiSetting {

}


export interface Chat {
  config: ChatConfig
  uiSetting: UiSetting
  msgList: CustomMsg[]
}


export interface CustomMsg {
  uuid: string
  type?: string,
  ui: CustomMsgUi,
  createdAt: number,
  sender: MsgSenderType,
  text: string,
  subText?: string,
  highLightText?: string,
  img?: string,
  isRead: boolean
  buttons?: CustomButtonCreate[]
}

export interface CustomButtonCreate {
  text: string,
  code: CustomButtonEvent
}

export interface CustomButtonEvent {
  eventType: EventType,
  value: {
    contentTitle: string,
    contentKey: string,
    data: string | object
  }
}

export enum EventType {
  Init = 'Init',
  Voice = 'Voice',
  Quiz = 'Quiz',
  Faq = 'Faq',
  Pronunciation = 'Pronunciation',
  Speech = 'Speech',
  giene = 'giene',
  shortCut = 'shortCut',
  Quick = 'Quick',
  Common = 'Common',
  URL = 'URL',
}

export enum ChatStatus {
  beFore = 'beFore',
  common = 'common',
  dict = 'dict',
  giene = 'giene',
  shortCut = 'shortCut',
  error = 'error',
}

export enum FloatingStatus {
  open = 'open',
  close = 'close',
}

export enum UiStatus {
  full = 'full',
  modal = 'modal',
}


export enum ChatBotPostMsgType {
  Init = 0,
  Event = 1,
  UI = 2,
  Status = 3,
  Style = 4,
  Clear = 5,
  Speech = 6,
  KeyWord = 7,
}

export interface ChatBotPostMsg {
  chatBotPostMsgType: ChatBotPostMsgType,
  customMsg?: CustomMsg,
  isVoice?: boolean,
  customButtonEvent?: CustomButtonEvent,
  data: string
}
