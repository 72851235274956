import CryptoUtil from "@/assets/lib/crypto/CryptoUtil";

export default class ChatUtility {
  static createKey() {
    const base = CryptoUtil.getRandomString(10) + Date.now()
    return CryptoUtil.sha256Hex(base)
  }

  static changeSafeText(text: string): string {
    return (this.escapeHTML(this.escapeScript(String(text))))
  }

  static escapeHTML(string: string) {
    return String(string).replace(/[&<>"'\\/]/g, '');
  }

  static escapeScript(string: string) {
    return String(string).replace(/<script[^>]*>([\S\s]*?)<\/script>/img, "");
  }

  static createNoticeTimeForm(date: number): string {
    const oldTime = new Date(date)
    const month = oldTime.getMonth() + 1
    const day = oldTime.getDate()
    const hour = oldTime.getHours()
    const min = oldTime.getMinutes()
    const hourText = oldTime.getHours() >= 12 ? 'PM' : 'AM'
    return `${month}.${day} ${hour}:${min} ${hourText}`
  }


}
