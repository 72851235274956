
import {Component, Vue} from 'vue-property-decorator'
import '@/assets/style/app.scss'
import Chat from "@/Chat.vue";
import ChatController from "@/assets/lib/controller/ChatController";
import ChatModel from "@/assets/lib/model/ChatModel";
import {ChatBotPostMsg, ChatBotPostMsgType, ChatStatus, UiStatus} from "@/assets/lib/type/Types";

@Component({
  name: 'App',
  components: {Chat}
})
export default class App extends Vue {

  uiStatus = UiStatus.full
  chatModel = new ChatModel()
  chatController = new ChatController(this.chatModel)

  get chatStatus() {
    return this.chatModel.status
  }

  created() {
    window.removeEventListener('message', this.receiveMsgHandler)
    window.addEventListener('message', this.receiveMsgHandler)
  }

  receiveMsgHandler(messageEvt: MessageEvent) {

    try {
      const msgParent = messageEvt.data as ChatBotPostMsg
      if (this.chatStatus === ChatStatus.beFore) {
        this.chatController.setStatus(ChatStatus.common);
      }
      console.log('msgParent', msgParent)
      switch (msgParent.chatBotPostMsgType) {
        case ChatBotPostMsgType.Init:
          //
          break;
        case ChatBotPostMsgType.Style:
          console.log('ChatBotPostMsgType.Style', ChatBotPostMsgType.Style)
          break;

        case ChatBotPostMsgType.UI:
          // if (msgParent.customMsg?.text.trim() === '' && msgParent.customMsg?.ui === CustomMsgUi.Text) return;
          if (msgParent.isVoice && msgParent.customMsg) this.chatController.voiceAnswer(msgParent.customMsg.text)
          this.handleUI(msgParent)
          break;

        case ChatBotPostMsgType.KeyWord:
          this.handleSpeechKeyword(msgParent)
          break;

        case ChatBotPostMsgType.Speech:
          this.handleSpeech(msgParent)
          break;
        case ChatBotPostMsgType.Clear:
          this.chatModel.clear()
          this.chatController.appendTime()
          break;
      }
    } catch (e) {
      return;
    }


    //TODO: 1. 음성 말하기, 2. 커스텀 UI제작
  }

  handleUI(msgParent: ChatBotPostMsg) {
    if (msgParent.customMsg) this.chatController.appendMsg(msgParent.customMsg)
  }

  handleSpeechKeyword(msgParent: ChatBotPostMsg) {
    if (!msgParent.customMsg) return
    if (msgParent.data === 'GenieInside') this.chatController.getAnswerGieneKeyword(msgParent.customMsg.text)
    if (msgParent.data === 'WordDictionary') this.chatController.getAnswerKoreanStandardDictKeyword(msgParent.customMsg.text)
  }

  handleSpeech(msgParent: ChatBotPostMsg) {
    this.chatController.appendMyMsg(msgParent.data, false)
    this.chatController.getAnswer(msgParent.data, true)
  }


  beforeDestroy() {
    window.removeEventListener('message', this.receiveMsgHandler)
  }

}
