
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import CommonTextBox from "@/views/ChatRoom/component/CommonTextBox.vue";
import {CustomButtonEvent, CustomMsg} from "@/assets/lib/type/Types"
import AiSteinAvatar from "@/views/ChatRoom/component/AiSteinAvatar.vue";

@Component({name: "SolveQuiz", components: {AiSteinAvatar, CommonTextBox}})
export default class SolveQuiz extends Vue {

  @Prop() isView!: boolean;
  @Prop() msg!: CustomMsg;

  @Emit('clickCustomButton')
  clickQuestion(customEvent: CustomButtonEvent, index: number) {
    const questions = this.$el.querySelectorAll('.answer')
    console.log(questions);

    questions.forEach((question, i) => {
      if (i === index) {
        question.classList.add('focus');
      } else {
        question.classList.remove('focus');
      }
    })

    return customEvent;
  }
}
