
import {Component, Vue} from "vue-property-decorator";
import CommonTextBox from "@/views/ChatRoom/component/CommonTextBox.vue"
import AiSteinAvatar from "@/views/ChatRoom/component/AiSteinAvatar.vue";
import CommonChatButton from "@/views/ChatRoom/component/CommonChatButton.vue";

@Component({name: "GoToQuiz", components: {CommonChatButton, AiSteinAvatar, CommonTextBox}})
export default class GoToQuiz extends Vue {

}
