import LocalStorageUtil from "@/assets/lib/utility/LocalStorageUtil";

export default class NumberStorage {
  private readonly key: string
  private _value: number


  constructor(key: string, defaultValue: number) {
    this.key = key;
    this._value = LocalStorageUtil.getNumber(this.key, defaultValue)
  }

  get value() { return this._value }
  set value(val: number) {
    this._value = Number(val)
    LocalStorageUtil.setNumber(this.key, this._value)
  }
}
