
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {Faq} from "@/assets/lib/type/ApiResponseTypes";

@Component({name: "ShortcutQuestion", components: {}})
export default class ShortcutQuestion extends Vue {

  @Prop() faqs!: Faq[]
  @Ref() refShortcutQuestion!: HTMLDivElement

  entireQuestion = false

  @Emit('clickFaq')
  clickQuestion(faq: Faq) {
    console.log('1234')
    return faq;
  }

  mounted() {
    console.log('this.refShortcutQuestion', this.refShortcutQuestion)
    this.cssMount()
    new ResizeObserver(this.cssMount).observe(this.$el)
  }

  showEntireQuestion() {
    this.cssMount()
  }

  cssMount() {
    this.entireQuestion = !this.entireQuestion;
    const defaultHeight = 720;
    const {width, height} = this.$el.getBoundingClientRect();
    let entireHeight;

    if (this.entireQuestion) {
      if (height > 70) {
        entireHeight = 600;
      }
    } else {
      entireHeight = 200;
    }

    if (
      this.refShortcutQuestion &&
      parseInt(this.refShortcutQuestion.style.maxHeight) !== entireHeight
    ) {
      this.refShortcutQuestion.style.maxHeight = `${entireHeight}px`;
    }

  }
}
