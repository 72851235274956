import axios, {AxiosError} from "axios";
import {
  CreateUUIDResponse,
  GieneAnswerResponse,
  OAuthLoginResponse,
  OAuthTokenResponse,
  ResponseData,
  UserInfoResponse
} from "@/assets/lib/type/ApiResponseTypes";
import {
  CreateUUIDRequest,
  EmptyRequest,
  OAuthLoginRequest,
  OAuthRefreshTokenRequest,
  OAuthTokenRequest,
  TextToSpeechRequest
} from "@/assets/lib/type/ApiRequestTypes";
import CryptoUtil from "@/assets/lib/crypto/CryptoUtil";
import Config from "@/config";
import Api from "@/assets/lib/api/Api";
import ChatManager from "@/assets/lib/manager/ChatManager";

interface ApiHeader {
  "x-auth-uuid": string,
  "x-auth-timestamp": string,
  "x-auth-signature": string,
  "x-transaction-id"?: string,
}

export default class GenieApi extends Api {
  private static genieAxios = axios.create({
    baseURL: Config.CONFIG.GENIE_HOST + '/api'
  })

  /**
   * OAuth login request
   * @param data
   * @param onSuc
   * @param onErr
   */
  static oAuthLogin<Q = OAuthLoginRequest, R = ResponseData<OAuthLoginResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.genieAxios, '/v1/oauth/login', data, null, onSuc, onErr);
  }

  /**
   * request access token using OAuth code
   * @param data
   * @param onSuc
   * @param onErr
   */
  static oAuthToken<Q = OAuthTokenRequest, R = ResponseData<OAuthTokenResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.genieAxios, '/v1/oauth/token', data, null, onSuc, onErr);
  }

  static oAuthRefreshToken<Q = OAuthRefreshTokenRequest, R = ResponseData<OAuthTokenResponse>>(token: string, data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.genieAxios, '/v1/oauth/token/refresh', data, {headers: {Authorization: `Bearer ${token}`}}, onSuc, onErr);
  }

  /**
   * request user info
   * @param token
   * @param data
   * @param onSuc
   * @param onErr
   */
  static userInfo<Q = EmptyRequest, R = ResponseData<UserInfoResponse>>(token: string, data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    if (!token) token = ChatManager.auth.session.token?.access_token || ''
    if (!token) throw new Error('no token')
    return this.getRequest<Q, R>(this.genieAxios, '/v1/user/info', data, {headers: {Authorization: `Bearer ${token}`}}, onSuc, onErr);
  }

  /**
   * 유저의 uuid 발급
   * @param data : { pcm : file }
   * @param onSuc
   * @param onErr
   */
  static getUuid<Q = CreateUUIDRequest, R = ResponseData<CreateUUIDResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.genieAxios, '/v1/user', data, {}, onSuc, onErr);
  }

  /**
   * 지니 챗봇과 대화
   * @param data : { test : 음성파일로 받을 텍스트 }
   * @param onSuc
   * @param onErr
   */
  static getAnswerGieneChatBot<Q = TextToSpeechRequest, R = ResponseData<GieneAnswerResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {

    const timeStamp = (new Date()).toISOString().replace(/[^0-9]/g, '');
    const header: ApiHeader = {
      "x-auth-timestamp": timeStamp,
      "x-auth-uuid": '17fa9095-e06d-5598-9857-1b808ac12067',
      "x-transaction-id": timeStamp,
      "x-auth-signature": CryptoUtil.signature(timeStamp),
    }

    return this.postRequest<Q, R>(this.genieAxios, '/v1/request-intent', data, {
      headers: Object.assign(header)
    }, onSuc, onErr);
  }

  /**
   * 텍스트를 음성 파일로 받아옴
   * @param data : { test : 음성파일로 받을 텍스트 }
   * @param onSuc
   * @param onErr
   */
  static textToSpeech<Q = TextToSpeechRequest, R = Blob>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {

    const timeStamp = (new Date()).toISOString().replace(/[^0-9]/g, '');
    const header: ApiHeader = {
      "x-auth-timestamp": timeStamp,
      "x-auth-uuid": '17fa9095-e06d-5598-9857-1b808ac12067',
      "x-transaction-id": timeStamp,
      "x-auth-signature": CryptoUtil.signature(timeStamp),
    }

    return this.postRequest<Q, R>(this.genieAxios, '/v1/text-to-speech', data, {
      headers: Object.assign(header),
      responseType: 'blob'
    }, onSuc, onErr);
  }
}
