
import {Component, Emit, Vue} from "vue-property-decorator";


@Component({name: "ShortcutButton", components: {}})
export default class ShortcutButton extends Vue {

  @Emit('openFaq')
  openFaq() {
    console.log('123')
  }

  @Emit('searchDict')
  statusWord(dict: string) {
    return dict
  }
}
