
import {Component, Prop, Vue} from "vue-property-decorator";
import CommonTextBox from "@/views/ChatRoom/component/CommonTextBox.vue";
import CommonChatButton from "@/views/ChatRoom/component/CommonChatButton.vue";
import AiSteinAvatar from "@/views/ChatRoom/component/AiSteinAvatar.vue";

@Component({name: "TextBook", components: {CommonTextBox, CommonChatButton, AiSteinAvatar}})
export default class TextBook extends Vue {
  @Prop() title !: string
  @Prop() url !: string


}
