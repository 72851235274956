
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import ChatRoom from "@/views/ChatRoom/ChatRoom.vue";
import FloatingWrapper from "@/components/FloatingWrapper.vue";
import {FloatingStatus, UiStatus} from "@/assets/lib/type/Types";
import ChatModel from "@/assets/lib/model/ChatModel";

@Component({
  name: "Chat",
  components: {FloatingWrapper, ChatRoom}
})
export default class Chat extends Vue {

  @Prop() chatModel!: ChatModel
  @Prop() uiStatus!: UiStatus
  @Prop() floatingStatus!: FloatingStatus
  @Ref() refAudio!: HTMLAudioElement;

  UiStatus = UiStatus;
  FloatingStatus = FloatingStatus;

  showModal() {
    this.floatingStatus = FloatingStatus.open
  }

  closeModal() {
    this.floatingStatus = FloatingStatus.close
  }


}
