import axios, {AxiosError} from "axios";
import {FaqAnswerResponse, FaqResponse, ResponseData} from "@/assets/lib/type/ApiResponseTypes";
import {FaqActionInfoRequest, SubCategoriesRequest} from "@/assets/lib/type/ApiRequestTypes";
import Config from "@/config";
import Api from "@/assets/lib/api/Api";

export default class FaqApi extends Api {
  private static faqAxios = axios.create({
    baseURL: Config.CONFIG.API_HOST + '/api/v1/faq'
  })

  /**
   * OAuth login request
   * @param data
   * @param onSuc
   * @param onErr
   */
  static firstFaq<Q = any, R = ResponseData<FaqResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.faqAxios, '/', data, null, onSuc, onErr);
  }

  /**
   * OAuth login request
   * @param data
   * @param onSuc
   * @param onErr
   */
  static subFaq<Q = SubCategoriesRequest, R = ResponseData<FaqResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.faqAxios, '/category', data, null, onSuc, onErr);
  }

  /**
   * OAuth login request
   * @param data
   * @param onSuc
   * @param onErr
   */
  static answerFaq<Q = FaqActionInfoRequest, R = ResponseData<FaqAnswerResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.faqAxios, '/answer', data, null, onSuc, onErr);
  }

}
