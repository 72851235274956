
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import {CustomMsg} from "@/assets/lib/type/Types";

@Component
export default class MyConversation extends Vue {
  @Prop() msg!: CustomMsg
  @Prop() isView!: boolean
  @Ref() refMyConversation!: HTMLDivElement

  mounted() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);

  }

  beforeDestroy() {
    window.removeEventListener('resize', this.updateHeight);
  }

  conversationHeight = 0
  updateHeight() {
    this.$nextTick(() => {
      this.conversationHeight = this.refMyConversation.getBoundingClientRect().height;
    });
  }
}
