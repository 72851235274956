
import {Component, Prop, Vue} from "vue-property-decorator";
import AiSteinAvatar from "@/views/ChatRoom/component/AiSteinAvatar.vue"
import {CustomMsg} from "@/assets/lib/type/Types";

@Component({name: "AiSteinFirstConversation", components: {AiSteinAvatar}})
export default class AiSteinConversation extends Vue {
  @Prop() msg!: CustomMsg
  @Prop() isView!: boolean

}
