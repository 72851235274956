
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';

@Component({
  name: 'Floating'
})
export default class Floating extends Vue {

  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() showChatModal!: Function;
  @Prop() btnCloseImg!: string;
  @Prop() state!: boolean;
  @Ref() refMsg!: HTMLSpanElement;

  msgMap: Map<string, boolean> = new Map()
  msgList: string[] = [];
  messageToggle = false
  msgCheckTimer: number | null = null;
  pushMsgIndex = 0;

  mounted() {
    window.removeEventListener('message', this.handleChatEvent)
    window.addEventListener('message', this.handleChatEvent)
  }

  beforeDestroy() {
    window.removeEventListener('message', this.handleChatEvent)
  }

  handleChatEvent(message: MessageEvent) {
    if (!this.state) return;
    if (!message.data.id) return;
    if (this.msgMap.get(message.data.id)) return;
    if (message.data.authorId) return;

    this.pushMsg(message.data.payload.text);
    this.msgMap.set(message.data.id, true);
  }


  showChat() {
    this.showChatModal()
    this.clearMsg()
    this.clearTimer()
  }

  badgeMaxItem() {
    if (this.msgList.length > 99) {
      return 99 + '+'
    } else {
      return this.msgList.length
    }
  }

  closeMessage() {
    this.messageToggle = false
  }

  checkPushMsg() {
    if (this.msgCheckTimer) return;
    this.msgCheckTimer = setInterval(() => {
      this.newMsg();
    }, 1 * 1000)
  }

  pushMsg(text: string) {
    this.msgList.push(text);
    this.checkPushMsg();
  }

  newMsg() {
    if (this.msgList.length - 1 < this.pushMsgIndex) return;
    const msg = this.msgList[this.pushMsgIndex];
    if (!msg || msg === '') return;
    this.refMsg.innerText = msg;
    this.messageToggle = true;
    this.pushMsgIndex++;
  }

  clearMsg() {
    this.msgList = [];
    this.refMsg.innerText = '';
    this.messageToggle = false;
  }

  clearTimer() {
    this.pushMsgIndex = 0;
    if (this.msgCheckTimer) clearInterval(this.msgCheckTimer)
    this.msgCheckTimer = null;
  }
}
