
import {Component, Prop, Vue} from 'vue-property-decorator';
import ChatRoom from '@/views/ChatRoom/ChatRoom.vue'
import ChatModel from "@/assets/lib/model/ChatModel";

@Component({
  components: {ChatRoom}
})
export default class ChatModal extends Vue {

  @Prop() chatModel!: ChatModel;
}
