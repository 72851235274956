
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import AiSteinAvatar from "@/views/ChatRoom/component/AiSteinAvatar.vue";
import CommonTextBox from "@/views/ChatRoom/component/CommonTextBox.vue";
import CommonChatButton from "@/views/ChatRoom/component/CommonChatButton.vue";
import TextBook from "@/views/ChatRoom/component/TextBook.vue";
import GoToQuiz from "@/views/ChatRoom/component/GoToQuiz.vue";
import GoToSpeakingPractice from "@/views/ChatRoom/component/GoToSpeakingPractice.vue";
import {CustomButtonEvent, CustomMsg} from "@/assets/lib/type/Types"

@Component({
  name: "AiConversationCard", components: {
    GoToSpeakingPractice,
    GoToQuiz, TextBook, CommonChatButton, CommonTextBox, AiSteinAvatar
  }
})
export default class AiConversation extends Vue {
  @Prop() isView !: boolean
  @Prop() msg!: CustomMsg

  currentTime = '';

  getNow() {
    let today = new Date();
    const date = ('0' + (today.getMonth() + 1)).slice(-2) + '.' + ('0' + today.getDate()).slice(-2);
    const time = today.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});
    const dateTime = date + ' ' + time
    return this.currentTime = dateTime;
  }


  @Emit('clickCustomButton')
  clickCustomButton(customButtonEvent: CustomButtonEvent) {
    return customButtonEvent
  }

  clickButton(customButtonEvent: CustomButtonEvent) {
    this.clickCustomButton(customButtonEvent)
  }

  mounted() {
    this.getNow();
  }


}
