import axios, {AxiosError} from "axios";
import {ResponseData} from "@/assets/lib/type/ApiResponseTypes";
import {RasaCommonAnswerRequest} from "@/assets/lib/type/ApiRequestTypes";
import Config from "@/config";
import Api from "@/assets/lib/api/Api";

export default class RasaApi extends Api {
  private static rasaAxios = axios.create({
    baseURL: Config.CONFIG.RASA_HOST
  })

  /**
   * get answer by rasa
   * @param data
   * @param onSuc
   * @param onErr
   */
  static commonAnswer<Q = RasaCommonAnswerRequest, R = ResponseData<any>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.rasaAxios, '/webhooks/rest/webhook', data, null, onSuc, onErr);
  }


}
