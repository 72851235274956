import {CreateUUIDRequest, OAuthTokenRequest} from "@/assets/lib/type/ApiRequestTypes";
import CryptoUtil from "@/assets/lib/crypto/CryptoUtil";
import ObjectStorage from '@/assets/lib/utility/ObjectStorage';
import {ChatUser, UserSession, UserUuid} from "@/assets/lib/type/Types";
import Logger from "@/assets/lib/debug/Logger"
import Config from "@/config";
import GenieApi from "@/assets/lib/api/GenieApi";
import ChatUtility from "@/assets/lib/util/ChatUtility";

export default class AuthController {
  private readonly logger = new Logger('[AuthController]')
  private _user = new ObjectStorage<ChatUser>('user-session', {
    uid: '',
    uuid: ChatUtility.createKey(),
    type: 'STUDENT',
    level: 1,
    name: '',
  })

  private _session = new ObjectStorage<UserSession>('giene-user-session', {
    user_idx: 0,
    uid: '',
    name: '',
    token: null
  })

  get session() {
    return this._session.value
  }

  private _uuid = new ObjectStorage<UserUuid>('user-uuid', {uid: '', uuid: null})

  get uuid() {
    return this._uuid.value
  }

  get chatUser() {
    return this._user.value
  }

  get hasValidSession() {
    return !!(this.session.token && this.session.uid)
  }

  /**
   * request access token using code
   * @param code
   */
  oAuthToken(code: string) {
    // generate login hash
    const hash = CryptoUtil.sha256(Config.CONFIG.OAuthClientId + Config.CONFIG.OAuthClientSecret + code)

    const req: OAuthTokenRequest = {
      client_id: Config.CONFIG.OAuthClientId,
      code,
      hash
    }
    return GenieApi.oAuthToken(req, async res => {
      this.session.token = res.data
      this._session.save()
    }, err => {
      this.logger.error('oAuthToken error', err)
    })
  }

  /**
   * update user session
   */
  updateUserSession() {
    if (!this.session.token) {
      this.logger.error('no token exists')
      return
    }

    return GenieApi.userInfo(this.session.token.access_token, {}, res => {
      Object.assign(this._session.value, res.data.user_info)
      this._session.save()
    }, err => {
      this.logger.error('updateUserSession error', err)
    })
  }

  /**
   * request uuid using signature
   * @param uid
   */
  setUuid(uid = 'test3211') {
    // generate login hash
    const timeStamp = (new Date()).toISOString().replace(/[^0-9]/g, '')

    const req: CreateUUIDRequest = {
      clientKey: Config.CONFIG.CLIENT_KEY,
      userId: uid,
      timestamp: timeStamp,
      signature: CryptoUtil.signature(timeStamp)
    }
    return GenieApi.getUuid(req, async res => {
      this.uuid.uuid = res.data.uuid
      this._uuid.save()
    }, err => {
      this.logger.error('uuid error', err)
    })
  }


}
